import React from 'react';
import {Route} from 'react-router-dom';
import {apiDocsPlugin, ApiExplorerPage} from '@backstage/plugin-api-docs';
import {CatalogEntityPage, CatalogIndexPage, catalogPlugin,} from '@backstage/plugin-catalog';
import {CatalogImportPage, catalogImportPlugin,} from '@backstage/plugin-catalog-import';
import {ScaffolderPage, scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {orgPlugin} from '@backstage/plugin-org';
import {SearchPage} from '@backstage/plugin-search';
import {TechRadarPage} from '@backstage-community/plugin-tech-radar';
import {TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage,} from '@backstage/plugin-techdocs';
import {TechDocsAddons} from '@backstage/plugin-techdocs-react';
import {ReportIssue} from '@backstage/plugin-techdocs-module-addons-contrib';
import {UserSettingsPage} from '@backstage/plugin-user-settings';
import {apis} from './apis';
import {entityPage} from './components/catalog/EntityPage';
import {searchPage} from './components/search/SearchPage';
import {Root} from './components/Root';
import LightIcon from '@material-ui/icons/WbSunny';
import DarkIcon from '@material-ui/icons/NightsStay';

import {AlertDisplay, OAuthRequestDialog, SignInPage} from '@backstage/core-components';
import {createApp} from '@backstage/app-defaults';
import {AppRouter, FlatRoutes} from '@backstage/core-app-api';
import {CatalogGraphPage} from '@backstage/plugin-catalog-graph';
import {RequirePermission} from '@backstage/plugin-permission-react';
import {catalogEntityCreatePermission} from '@backstage/plugin-catalog-common/alpha';

import {githubAuthApiRef} from '@backstage/core-plugin-api';
import {BlogPage} from '@internal/plugin-blog';
import {UnifiedThemeProvider} from "@backstage/theme";
import {darkTheme, lightTheme} from "./theme/theme";
import {HomepageCompositionRoot} from "@backstage/plugin-home";
import {HomePage} from "./components/home/HomePage";
import {LighthousePage} from "@backstage-community/plugin-lighthouse";


const app = createApp({
    apis,
    themes: [{
        id: 'dark',
        title: 'Dark',
        variant: 'dark',
        icon: <DarkIcon/>,
        Provider: ({children}) => (
            <UnifiedThemeProvider theme={darkTheme} children={children}/>
        )
    },
        {
            id: 'light',
            title: 'Light',
            variant: 'light',
            icon: <LightIcon/>,
            Provider: ({children}) => (
                <UnifiedThemeProvider theme={lightTheme} children={children}/>
            )
        }
    ],
    components: {
        SignInPage: props => (
            <SignInPage
                {...props}
                auto
                provider={{
                    id: 'github-auth-provider',
                    title: 'GitHub',
                    message: 'Sign in using GitHub',
                    apiRef: githubAuthApiRef,
                }}
            />
        ),
    },
    bindRoutes({bind}) {
        bind(catalogPlugin.externalRoutes, {
            createComponent: scaffolderPlugin.routes.root,
            viewTechDoc: techdocsPlugin.routes.docRoot,
            createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
        });
        bind(apiDocsPlugin.externalRoutes, {
            registerApi: catalogImportPlugin.routes.importPage,
        });
        bind(scaffolderPlugin.externalRoutes, {
            registerComponent: catalogImportPlugin.routes.importPage,
            viewTechDoc: techdocsPlugin.routes.docRoot,
        });
        bind(orgPlugin.externalRoutes, {
            catalogIndex: catalogPlugin.routes.catalogIndex,
        });
    },
});

const routes = (
    <FlatRoutes>
        <Route path="/" element={<HomepageCompositionRoot/>}>
            <HomePage/>
        </Route>
        <Route path="/catalog" element={<CatalogIndexPage/>}/>
        <Route
            path="/catalog/:namespace/:kind/:name"
            element={<CatalogEntityPage/>}
        >
            {entityPage}
        </Route>
        <Route path="/docs" element={<TechDocsIndexPage/>}/>
        <Route
            path="/docs/:namespace/:kind/:name/*"
            element={<TechDocsReaderPage/>}
        >
            <TechDocsAddons>
                <ReportIssue/>
            </TechDocsAddons>
        </Route>
        <Route path="/create" element={<ScaffolderPage/>}/>
        <Route path="/api-docs" element={<ApiExplorerPage/>}/>
        <Route
            path="/tech-radar"
            element={<TechRadarPage width={1500} height={800}/>}
        />
        <Route
            path="/catalog-import"
            element={
                <RequirePermission permission={catalogEntityCreatePermission}>
                    <CatalogImportPage/>
                </RequirePermission>
            }
        />
        <Route path="/search" element={<SearchPage/>}>
            {searchPage}
        </Route>
        <Route path="/settings" element={<UserSettingsPage/>}/>
        <Route path="/catalog-graph" element={<CatalogGraphPage/>}/>
        <Route path="/blog" element={<BlogPage/>}/>
        <Route path="/lighthouse" element={<LighthousePage/>}/>
    </FlatRoutes>
);

export default app.createRoot(
    <>
        <AlertDisplay/>
        <OAuthRequestDialog/>
        <AppRouter>
            <Root>{routes}</Root>
        </AppRouter>
    </>,
);
