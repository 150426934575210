import {createBaseThemeOptions, createUnifiedTheme, palettes} from "@backstage/theme";

export const darkTheme = createUnifiedTheme({
        ...createBaseThemeOptions({
            palette: {
                ...palettes.dark,
                background: {
                    ...palettes.dark.background,
                    default: "#171717",
                    paper: "#1e1e1e"
                },
                navigation: {
                    ...palettes.dark.navigation,
                    background: "#1e1e1e"
                },
                primary: {
                    main: "#4433FF"
                },
                secondary: {
                    main: "#FF275D"
                },
            }
        })
    }
)

export const lightTheme = createUnifiedTheme({
        ...createBaseThemeOptions({
            palette: {
                ...palettes.light,
                background: {
                    ...palettes.dark.background,
                    default: "#ffffff",
                    paper: "#ffffff"
                },
                primary: {
                    main: "#4433FF"
                },
                secondary: {
                    main: "#FF275D"
                },
            }
        })
    }
)