import {createPlugin, createRoutableExtension} from '@backstage/core-plugin-api';

import {rootRouteRef} from './routes';

export const blogPlugin = createPlugin({
    id: 'blog',
    routes: {
        root: rootRouteRef,
    },
});

export const BlogPage = blogPlugin.provide(
    createRoutableExtension({
        name: 'BlogPage',
        component: () =>
            import('./components/dashboard').then(m => m.Dashboard),
        mountPoint: rootRouteRef,
    }),
);
