import React, {PropsWithChildren} from 'react';
import {makeStyles} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import CategoryIcon from '@material-ui/icons/Category';
import HighlightIcon from '@material-ui/icons/Highlight';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {Settings as SidebarSettings, UserSettingsSignInAvatar,} from '@backstage/plugin-user-settings';
import {SidebarSearchModal} from '@backstage/plugin-search';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import {
    Link,
    Sidebar,
    sidebarConfig,
    SidebarDivider,
    SidebarGroup,
    SidebarItem,
    SidebarPage,
    SidebarScrollWrapper,
    SidebarSpace,
    useSidebarOpenState,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';

const useSidebarLogoStyles = makeStyles({
    root: {
        width: sidebarConfig.drawerWidthClosed,
        height: 3 * sidebarConfig.logoHeight,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        marginBottom: -14,
    },
    link: {
        width: sidebarConfig.drawerWidthClosed,
        marginLeft: 24,
    },
});

const SidebarLogo = () => {
    const classes = useSidebarLogoStyles();
    const {isOpen} = useSidebarOpenState();

    return (
        <div className={classes.root}>
            <Link to="/" underline="none" className={classes.link} aria-label="Home">
                {isOpen ? <LogoFull/> : <LogoIcon/>}
            </Link>
        </div>
    );
};

export const Root = ({children}: PropsWithChildren<{}>) => {
    return (
        <SidebarPage>
            <Sidebar>
                <SidebarLogo/>
                <SidebarGroup label="Search" icon={<SearchIcon/>} to="/search">
                    <SidebarSearchModal/>
                </SidebarGroup>
                <SidebarDivider/>
                <SidebarGroup label="Menu" icon={<MenuIcon/>}>
                    {/* Global nav, not org-specific */}
                    <SidebarItem icon={HomeIcon} to="/" text="Home"/>
                    <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog"/>
                    <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs"/>
                    <SidebarItem icon={LibraryBooks} to="docs" text="Docs"/>
                    <SidebarItem icon={CreateComponentIcon} to="create" text="Create..."/>
                    {/* End global nav */}
                    <SidebarDivider/>
                    <SidebarScrollWrapper>
                        <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar"/>
                        <SidebarDivider/>
                        <SidebarItem icon={HighlightIcon} to="lighthouse" text="Lighthouse"/>
                        <SidebarDivider/>
                        <SidebarItem icon={DeveloperBoardIcon} to="blog" text="eliasblog.org"/>
                    </SidebarScrollWrapper>
                </SidebarGroup>
                <SidebarSpace/>
                <SidebarDivider/>

                <SidebarGroup
                    label="Settings"
                    icon={<UserSettingsSignInAvatar/>}
                    to="/settings"
                >
                    <SidebarSettings/>
                </SidebarGroup>
            </Sidebar>
            {children}
        </SidebarPage>
    );
}
