import React from 'react';
import {Box, Grid} from "@material-ui/core";
import {HomePageSearchBar} from "@backstage/plugin-search";
import {
    HomePageCompanyLogo,
    HomePageRecentlyVisited,
    HomePageStarredEntities,
    HomePageTopVisited,
} from "@backstage/plugin-home";

export const HomePage = () => (
    <Box style={{padding: "20px", width: "100%"}}>
        <Grid container spacing={3}>
            <Grid item container justifyContent={"center"} style={{marginTop: "70px"}}>
                <Grid item>
                    <HomePageCompanyLogo/>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <HomePageSearchBar/>
            </Grid>
            <Grid item xs={12} md={6}>
                <HomePageRecentlyVisited/>
            </Grid>
            <Grid item xs={12} md={6}>
                <HomePageTopVisited/>
            </Grid>
            <Grid item xs={12}>
                <HomePageStarredEntities/>
            </Grid>
        </Grid>
    </Box>

);